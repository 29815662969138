<template>
  <svg
    class="group"
    width="436"
    height="455"
    viewBox="0 0 400 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :class="props.backgroundClass" filter="url(#filter0_dddddd_5623_14788)">
      <path
        d="M80.3555 57C80.3555 25.5198 105.875 0 137.355 0H298.355C329.836 0 355.355 25.5198 355.355 57V218C355.355 249.48 329.836 275 298.355 275H137.355C105.875 275 80.3555 249.48 80.3555 218V57Z"
        fill="currentColor"
      />
    </g>
    <g :class="props.iconClass" filter="url(#filter1_i_5623_14788)">
      <path
        d="M191.073 74.4399C170.636 74.4399 154.007 91.0786 154.007 111.521V164.48C154.007 184.92 170.646 201.551 191.088 201.551H244.048C264.487 201.551 281.118 184.912 281.118 164.47V111.506C281.118 91.0688 264.48 74.4399 244.038 74.4399H191.073ZM255.696 94.7777C258.503 94.7777 260.781 97.0556 260.781 99.8622C260.781 102.669 258.503 104.947 255.696 104.947C252.89 104.947 250.612 102.669 250.612 99.8622C250.612 97.0556 252.89 94.7777 255.696 94.7777ZM217.563 104.947C235.791 104.947 250.612 119.768 250.612 137.996C250.612 156.223 235.788 171.044 217.563 171.044C199.335 171.044 184.514 156.221 184.514 137.996C184.514 119.77 199.335 104.947 217.563 104.947ZM217.563 115.116C204.925 115.116 194.683 125.358 194.683 137.996C194.683 150.633 204.925 160.876 217.563 160.876C230.2 160.876 240.443 150.633 240.443 137.996C240.443 125.358 230.2 115.116 217.563 115.116Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_5623_14788"
        x="0.355469"
        y="0"
        width="435"
        height="455"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76726" />
        <feGaussianBlur stdDeviation="1.1069" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5623_14788"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.6501" />
        <feGaussianBlur stdDeviation="2.66004" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5623_14788"
          result="effect2_dropShadow_5623_14788"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.5216" />
        <feGaussianBlur stdDeviation="5.00862" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5623_14788"
          result="effect3_dropShadow_5623_14788"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="22.3363" />
        <feGaussianBlur stdDeviation="8.93452" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5623_14788"
          result="effect4_dropShadow_5623_14788"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="41.7776" />
        <feGaussianBlur stdDeviation="16.711" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5623_14788"
          result="effect5_dropShadow_5623_14788"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="100" />
        <feGaussianBlur stdDeviation="40" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5623_14788"
          result="effect6_dropShadow_5623_14788"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5623_14788"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_5623_14788"
        x="136.211"
        y="56.6445"
        width="162.702"
        height="166.702"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_5623_14788"
        />
      </filter>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-black group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
