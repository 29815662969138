<template>
  <svg
    class="group"
    width="436"
    height="456"
    viewBox="0 0 400 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :class="props.backgroundClass" filter="url(#filter0_dddddd_5623_14813)">
      <path
        d="M80.3555 57.1777C80.3555 25.6975 105.875 0.177734 137.355 0.177734H298.355C329.836 0.177734 355.355 25.6975 355.355 57.1777V218.178C355.355 249.658 329.836 275.178 298.355 275.178H137.355C105.875 275.178 80.3555 249.658 80.3555 218.178V57.1777Z"
        fill="currentColor"
      />
    </g>
    <g :class="props.iconClass" filter="url(#filter1_i_5623_14813)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M151.577 82.5957C151.577 79.6265 152.756 76.7789 154.856 74.6794C156.955 72.5799 159.803 71.4004 162.772 71.4004H274.371C275.843 71.398 277.3 71.6858 278.66 72.2475C280.02 72.8091 281.256 73.6334 282.297 74.6733C283.338 75.7132 284.164 76.9482 284.727 78.3077C285.29 79.6671 285.58 81.1242 285.579 82.5957V194.195C285.58 195.667 285.292 197.124 284.73 198.485C284.167 199.845 283.342 201.08 282.302 202.121C281.262 203.162 280.026 203.988 278.666 204.551C277.307 205.114 275.849 205.403 274.378 205.403H162.772C161.301 205.403 159.845 205.113 158.486 204.55C157.128 203.987 155.893 203.162 154.854 202.121C153.814 201.081 152.989 199.846 152.427 198.487C151.865 197.128 151.576 195.672 151.577 194.201V82.5957ZM204.617 122.492H222.762V131.604C225.381 126.366 232.082 121.651 242.15 121.651C261.452 121.651 266.027 132.085 266.027 151.229V186.691H246.493V155.59C246.493 144.687 243.874 138.536 237.222 138.536C227.994 138.536 224.157 145.169 224.157 155.59V186.691H204.617V122.492ZM171.117 185.857H190.657V121.651H171.117V185.85V185.857ZM193.452 100.71C193.489 102.383 193.191 104.047 192.577 105.603C191.962 107.16 191.042 108.578 189.872 109.774C188.702 110.97 187.305 111.921 185.762 112.57C184.22 113.218 182.563 113.553 180.89 113.553C179.216 113.553 177.56 113.218 176.017 112.57C174.475 111.921 173.077 110.97 171.907 109.774C170.737 108.578 169.817 107.16 169.203 105.603C168.588 104.047 168.29 102.383 168.327 100.71C168.399 97.4264 169.755 94.3013 172.103 92.0044C174.451 89.7074 177.605 88.4212 180.89 88.4212C184.174 88.4212 187.329 89.7074 189.677 92.0044C192.025 94.3013 193.38 97.4264 193.452 100.71Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_5623_14813"
        x="0.355469"
        y="0.177734"
        width="435"
        height="455"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.76726" />
        <feGaussianBlur stdDeviation="1.1069" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5623_14813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6.6501" />
        <feGaussianBlur stdDeviation="2.66004" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5623_14813"
          result="effect2_dropShadow_5623_14813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12.5216" />
        <feGaussianBlur stdDeviation="5.00862" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.035 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5623_14813"
          result="effect3_dropShadow_5623_14813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="22.3363" />
        <feGaussianBlur stdDeviation="8.93452" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0417275 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5623_14813"
          result="effect4_dropShadow_5623_14813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="41.7776" />
        <feGaussianBlur stdDeviation="16.711" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0503198 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5623_14813"
          result="effect5_dropShadow_5623_14813"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="100" />
        <feGaussianBlur stdDeviation="40" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5623_14813"
          result="effect6_dropShadow_5623_14813"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5623_14813"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_5623_14813"
        x="145.486"
        y="65.3093"
        width="146.184"
        height="150.184"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="7.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_5623_14813"
        />
      </filter>
    </defs>
  </svg>
</template>
<script setup>
const props = defineProps({
  backgroundClass: {
    type: String,
    required: false,
    default: "text-black group-hover:text-white",
  },
  iconClass: {
    type: String,
    required: false,
    default: "text-white group-hover:text-primary",
  },
});
</script>
